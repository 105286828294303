import React from "react"
import SocialIcon from "./socialLink"
import Loadable from "@loadable/component"
import styles from "./hero.module.css"
import { graphql, useStaticQuery } from "gatsby"

const Doodle = Loadable(() => import("../components/doodle"))

export default () => {
  const links = useStaticQuery(graphql`
     {
      allSocialLinksJson {
        edges {
          node {
            id
            title
            url
            icon {
              value
              color
              bgColor
              colorHover
            }
          }
        }
      }
    }
  `);
  return(
  <div className={styles.root}>
    <div className={styles.text}>
      <h1>Hi, I'm Joe Wells <span role="img" aria-label="Waving Hand">👋</span></h1>
      <p className={styles.description}>I'm a software engineer <span role="img" aria-label="Man using laptop">👨‍💻</span></p>
      <div className={styles.socialContainer}>
         {links.allSocialLinksJson.edges.map((it) => (
          <SocialIcon key={it.node.id} title={it.node.title} url={it.node.url} icon={it.node.icon} />
        ))}
      </div>
    </div>
    <div className={styles.doodleContainer}>
      <Doodle rule={'var(--doodle-background);'} />
    </div>
  </div>
)}