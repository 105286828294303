import React from "react"
import SEO from "../components/seo"
import Loadable from "@loadable/component"
import DarkModeToggle from "../components/darkModeToggle"
import Hero from "../components/hero"
import NoscriptHider from "../components/noscriptHider"
import styles from "./index.module.css"

const Firebase = Loadable(() => import("../components/firebase"))

const IndexPage = () => (
  <div className={styles.root}>
    <SEO title="Home" />
    <Firebase />
    <NoscriptHider selectors={[
      ".noscript-hide"
    ]} />
    <div className={styles.heroContainer}>
      <Hero />
    </div>
    <div className={styles.toggleContainer}>
      <DarkModeToggle moonColor="var(--black-olive)" sunColor="var(--floral-white)" />
    </div>
  </div>
)

export default IndexPage
