import React from 'react';
import { ThemeToggler } from "gatsby-plugin-dark-mode"
import darkModeToggleStyles from './darkModeToggle.module.css'

export default ({ moonColor = '#000', sunColor = '#fff' }) => (
  <ThemeToggler>
    {({ theme, toggleTheme }) => (
      <label
        className={`${darkModeToggleStyles.darkModeToggle} noscript-hide`}
        style={{'--moon-color': moonColor, '--sun-color': sunColor}}
        title={`Activate ${theme === 'dark' ? 'light' : 'dark'} mode`}>
        <input
          type="checkbox"
          role="button"
          aria-label={`Activate ${theme === 'dark' ? 'light' : 'dark'} mode`}
          onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')}
          checked={theme === 'dark'}
        />
        <div />
      </label>
    )}
  </ThemeToggler>
)